import "./App.css";
import MainPage from "./components/MainPage";
import { CookieSetting } from "cookiesetting-component";

function App() {
  return (
    <div className="App">
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
      <MainPage></MainPage>
    </div>
  );
}

export default App;
